export function ArrowDown() {
  return (
    <svg
      width="24"
      height="14"
      viewBox="0 0 24 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0607 13.0607C12.4749 13.6464 11.5251 13.6464 10.9393 13.0607L1.3934 3.51472C0.807611 2.92893 0.807611 1.97919 1.3934 1.3934C1.97918 0.807612 2.92893 0.807612 3.51472 1.3934L12 9.87868L20.4853 1.3934C21.0711 0.807611 22.0208 0.807611 22.6066 1.3934C23.1924 1.97918 23.1924 2.92893 22.6066 3.51472L13.0607 13.0607ZM13.5 11L13.5 12L10.5 12L10.5 11L13.5 11Z"
        fill="black"
      />
    </svg>
  );
}
