export const texts = {
  Slider: [
    {
      title: "Судосталь",
      marks: "D40S, E40S, A40S, РСА, РСВ, РСD, РСD-32 и др.",
      products: "Лист | Уголок | Канат | Полособульб | РКО и РМРС",
      product_bg: "ShipboardSteel.png",
      icons: ["ShipboardSteel1.png", "ShipboardSteel2.png", "RKO.png"],
    },
    {
      title: "Износостойкая сталь",
      marks: "Hardox | Sidur | Magstrong | Powerhard | Quard",
      products:
        "Лист особой прочности для горнодобывающей, машиностроительной и агропромышленной отрасли",
      product_bg: "MiningSteel.png",
      icons: ["MiningSteel1.png", "MiningSteel2.png"],
    },
    {
      title: "Легированные, специальные, инструментальные стали и сплавы",
      marks:
        "09Г2С, 10ХСНД, 17Г1С, 30ХГСА, 38ХА, 65Г, Х12МФ, Р18, 38ХС, ХВГ и др.",
      products: "Круг | Шестигранник | Уголок | Швеллер | Балка | Лист | Труба",
      product_bg: "SpecialSteel.png",
      icons: ["SpecialSteel1.png", "SpecialSteel2.png", "SpecialSteel3.png"],
    },
    {
      title: "Коррозионностойкая (нержавеющая) сталь",
      marks:
        "12Х18Н10Т, 08Х18Н10Т, 14Х17Н2, 06ХН28МДТ, 10Х17Н13М2Т, 20Х23Н18, AISI и др.",
      products:
        "Лист | Полоса | Лента | Труба | Отводы | Фланцы | Тройники | Круг | Шестигранник | Квадрат | Уголок | Проволока",
      product_bg: "StainlessSteel.png",
      icons: ["Wending1.png", "Wending2.png"],
    },
    {
      title: "Цветной прокат",
      marks: "Алюминий | Дюраль | Латунь | Бронза | Медь | Свинец | Олово",
      products:
        "Изделия общего (лист, круг, труба, уголок, шестигранник, проволока) и специального (припой, аноды, катоды, протекторы, втулки) назначения, редкие сплавы, кабель",
      product_bg: "ColoredSteel.png",
      icons: ["ColoredSteel1.png", "ColoredSteel2.png"],
    },
    {
      title: "Сварочные материалы и прокат с дополнительной обработкой",
      marks:
        "Сварочная проволока, электроды общего и специального назначения (ФГУП ЦНИИ КМ «Прометей», НАКС)",
      products:
        "Трубы и листы с покрытием, калиброванный прокат, ПВЛ, перфорация, металлоконструкции",
      product_bg: "WeldingSteel.png",
      icons: ["WeldingSteel1.png", "WeldingSteel2.png"],
    },
  ],
  Requisites: [
    {
      title: `Полное наименование организации:`,
      text: `Общество с ограниченной ответственностью «Промышленное строительство»`,
    },
    {
      title: `Сокращённое наименование:`,
      text: `ООО «Промышленное строительство»`,
    },
    {
      title: `Юридический адрес предприятия в соответствии с учредительными документами:`,
      text: `454020, Челябинская обл., г.Челябинск, ул.Курчатова, д.23-Б, оф.901`,
    },
    {
      title: `Почтовый адрес предприятия:`,
      text: `454020, Челябинская обл., г.Челябинск, ул.Курчатова, д.23-Б, оф.901`,
    },
    {
      title: `Идентификационный номер ИНН/КПП:`,
      text: `7453038164 / 745301001`,
    },
    {
      title: `ОГРН:`,
      text: `1027403887829`,
    },
    {
      title: `Код ОКПО:`,
      text: `34559635`,
    },
    {
      title: `Код ОКВЭД:`,
      text: `46.72.21; 46.69; 46.73.6; 46.90`,
    },
    {
      title: `Код ОКФС:`,
      text: `16`,
    },
    {
      title: `Код ОКОПФ:`,
      text: `65`,
    },
    {
      title: `Банковские реквизиты:`,
      text: `р/сч 40702810772000007218 в Челябинском отделении №8597 ПАО «Сбербанк России», к/сч 30101810700000000602, БИК 047501602`,
    },
    {
      title: `Отгрузочные реквизиты:`,
      text: `Станция «Челябинск-Грузовой» ЮУЖД Код станции 800101 Код предприятия 1610`,
    },
    {
      title: `Телефон:`,
      text: `(351) 247-46-90, 247-46-91, 247-46-92, 247-46-93`,
    },
    {
      title: `Email:`,
      text: `metall@promstroy74.ru`,
    },
  ],
};
