interface Props {
  className?: string;
}

export function WhiteLogo({ className }: Props) {
  return (
    <svg
      className={className}
      viewBox="0 0 1871 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M292.16 131.313L276.261 256.788H245.613L265.152 102.218H341.963L322.425 256.788H291.777L307.676 131.313H292.16Z"
        fill="white"
      />
      <path
        d="M409.941 160.409H394.33L385.805 227.693H401.321L409.941 160.409ZM367.417 131.313H444.227L428.33 256.788H382.166L376.42 302.25H345.771L367.417 131.313Z"
        fill="white"
      />
      <path
        d="M548.807 131.313L532.906 256.788H456.095L471.996 131.313H548.807ZM514.52 160.409H499.004L490.382 227.693H505.898L514.52 160.409Z"
        fill="white"
      />
      <path
        d="M602.146 170.865H600.421L599.558 189.959L591.322 256.788H560.676L576.573 131.313H622.448L627.429 201.689L627.525 206.235H628.387L629.441 201.689L653.098 131.313H699.74L683.939 256.788H653.193L662.102 189.959L665.262 172.411H663.919L633.941 256.788H611.147L602.146 170.865Z"
        fill="white"
      />
      <path
        d="M847.479 131.313L831.578 256.788H800.932L816.829 131.313H847.479ZM742.797 227.693H758.408L761.183 205.962H745.572L742.797 227.693ZM708.51 256.788L724.407 131.313H755.053L749.309 176.866H795.47L785.416 256.788H708.51Z"
        fill="white"
      />
      <path
        d="M979.224 256.788H856.248L872.149 131.313H902.795L890.535 227.693H906.051L918.311 131.313H948.96L936.701 227.693H952.216L964.476 131.313H995.122L979.224 256.788Z"
        fill="white"
      />
      <path
        d="M1110.02 131.313L1094.12 256.788H1063.47L1075.73 160.409H1063.66L1046.04 256.788H1003.9L1007.54 227.693H1020.66L1038.28 131.313H1110.02Z"
        fill="white"
      />
      <path
        d="M1120.99 256.788L1136.88 131.313H1213.69L1203.54 211.235H1157.38L1155.27 227.693H1201.43L1197.8 256.788H1120.99ZM1161.11 182.14H1176.53L1179.31 160.409H1163.89L1161.11 182.14Z"
        fill="white"
      />
      <path
        d="M1281.5 179.503L1287.63 131.313H1318.28L1302.37 256.788H1271.73L1277.86 208.599H1262.34L1256.21 256.788H1225.56L1241.46 131.313H1272.11L1265.98 179.503H1281.5Z"
        fill="white"
      />
      <path
        d="M1386.08 179.503L1392.21 131.313H1422.85L1406.95 256.788H1376.3L1382.43 208.599H1366.92L1360.79 256.788H1330.14L1346.04 131.313H1376.69L1370.56 179.503H1386.08Z"
        fill="white"
      />
      <path
        d="M1527.43 131.313L1511.53 256.788H1434.72L1450.62 131.313H1527.43ZM1493.15 160.409H1477.63L1469.01 227.693H1484.52L1493.15 160.409Z"
        fill="white"
      />
      <path
        d="M1539.3 256.788L1555.2 131.313H1632.01L1621.86 211.235H1575.69L1573.59 227.693H1619.75L1616.11 256.788H1539.3ZM1579.43 182.14H1594.85L1597.63 160.409H1582.21L1579.43 182.14Z"
        fill="white"
      />
      <path
        d="M470.225 294.23L466.583 323.325H435.747L427.125 390.609H457.965L454.323 419.705H392.838L408.736 294.23H470.225Z"
        fill="white"
      />
      <path
        d="M526.019 323.325L513.759 419.705H483.113L499.011 294.23H621.987L606.086 419.705H575.44L587.7 323.325H572.184L559.924 419.705H529.275L541.535 323.325H526.019Z"
        fill="white"
      />
      <path
        d="M689.184 323.325H673.573L665.05 390.609H680.566L689.184 323.325ZM646.66 294.23H723.471L707.574 419.705H661.409L655.664 465.168H625.015L646.66 294.23Z"
        fill="white"
      />
      <path
        d="M828.051 294.23L812.15 419.705H735.339L751.24 294.23H828.051ZM793.764 323.325H778.248L769.626 390.609H785.142L793.764 323.325Z"
        fill="white"
      />
      <path
        d="M839.92 419.705L854.955 294.23H885.314L878.325 341.874L874.974 358.786H875.833L904.662 294.23H936.365L921.327 419.705H890.967L897.96 371.97L901.215 355.058H900.353L871.619 419.705H839.92Z"
        fill="white"
      />
      <path
        d="M990.883 323.325L978.623 419.705H947.974L963.875 294.23H1086.85L1070.95 419.705H1040.3L1052.56 323.325H1037.04L1024.78 419.705H994.139L1006.4 323.325H990.883Z"
        fill="white"
      />
      <path
        d="M1095.62 419.705L1111.52 294.23H1188.34L1178.18 374.152H1132.02L1129.91 390.609H1176.08L1172.43 419.705H1095.62ZM1135.75 345.056H1151.17L1153.95 323.325H1138.53L1135.75 345.056Z"
        fill="white"
      />
      <path
        d="M1306.32 294.23L1290.42 419.705H1259.77L1272.03 323.325H1259.97L1242.34 419.705H1200.2L1203.84 390.609H1216.96L1234.59 294.23H1306.32Z"
        fill="white"
      />
      <path
        d="M1367.09 390.609L1369.87 368.878H1354.35L1351.58 390.609H1367.09ZM1394.1 419.705H1317.29L1333.19 294.23H1363.84L1358.09 339.783H1404.25L1394.1 419.705Z"
        fill="white"
      />
      <path
        d="M1499.25 294.23L1495.62 323.325H1464.78L1456.16 390.609H1486.99L1483.36 419.705H1421.87L1437.77 294.23H1499.25Z"
        fill="white"
      />
      <path
        d="M1555.05 323.325L1542.79 419.705H1512.14L1528.04 294.23H1651.01L1635.12 419.705H1604.47L1616.73 323.325H1601.21L1588.95 419.705H1558.31L1570.57 323.325H1555.05Z"
        fill="white"
      />
      <path
        d="M1700.3 341.51H1715.63L1717.93 323.325H1702.7L1700.3 341.51ZM1709.5 390.609L1711.99 370.606H1696.67L1694.08 390.609H1709.5ZM1736.6 419.705H1659.79L1675.69 294.23H1752.5L1746.28 341.51L1728.17 356.058L1742.64 370.606L1736.6 419.705Z"
        fill="white"
      />
      <path
        d="M1857.08 294.23L1841.18 419.705H1764.37L1780.27 294.23H1857.08ZM1822.79 323.325H1807.28L1798.66 390.609H1814.17L1822.79 323.325Z"
        fill="white"
      />
      <path
        d="M1307.41 451.264H1316.93L1314.43 470.926H1319.25L1321.75 451.264H1331.27L1325.21 499.288H1315.68L1318.12 479.967H1303.78L1307.41 451.264Z"
        fill="white"
      />
      <path
        d="M1344.83 499.288L1349.77 460.303H1373.63L1370.48 485.134H1356.13L1355.48 490.247H1369.82L1368.69 499.288H1344.83ZM1357.29 476.096H1362.08L1362.94 469.343H1358.15L1357.29 476.096Z"
        fill="white"
      />
      <path
        d="M1420.87 460.303L1415.93 499.288H1406.4L1410.21 469.343H1406.46L1400.99 499.288H1387.9L1389.03 490.247H1393.1L1398.58 460.303H1420.87Z"
        fill="white"
      />
      <path
        d="M1451.13 486.037L1444.88 499.288H1434.85L1441.31 486.037H1437.23L1440.54 460.303H1466.04L1460.98 499.288H1451.46L1453.18 486.037H1451.13ZM1447.91 476.943H1454.34L1455.35 469.343H1448.9L1447.91 476.943Z"
        fill="white"
      />
      <path
        d="M1491.69 483.498L1490.83 490.247H1495.65L1496.51 483.498H1491.69ZM1508.98 460.303L1507.85 469.343H1493.48L1492.82 474.457H1507.16L1504.01 499.288H1480.14L1485.11 460.303H1508.98Z"
        fill="white"
      />
      <path
        d="M1523.24 499.288L1527.92 460.303H1537.35L1535.18 475.105L1534.14 480.36H1534.4L1543.36 460.303H1553.21L1548.54 499.288H1539.11L1541.28 484.459L1542.29 479.204H1542.02L1533.1 499.288H1523.24Z"
        fill="white"
      />
      <path
        d="M1584.78 475.277L1586.68 460.303H1596.2L1591.26 499.288H1581.74L1583.65 484.314H1578.82L1576.92 499.288H1567.4L1572.34 460.303H1581.86L1579.96 475.277H1584.78Z"
        fill="white"
      />
      <path
        d="M1634.51 460.303L1633.38 469.343H1623.8L1621.12 490.247H1630.7L1629.57 499.288H1610.47L1615.41 460.303H1634.51Z"
        fill="white"
      />
      <path
        d="M1667.63 474.117L1669.54 460.303H1679.69L1676.86 483.467H1673.05L1676.71 499.288H1666.68L1663.23 483.467H1660.67L1658.62 499.288H1649.09L1654.04 460.303H1663.56L1661.8 474.117H1667.63Z"
        fill="white"
      />
      <path
        d="M1703.42 499.288H1693.84L1694.97 490.247H1704.55L1703.42 499.288Z"
        fill="white"
      />
      <path
        d="M1743.56 454.088L1737.52 499.288H1728L1732.82 463.128H1728.45L1729.58 454.088H1743.56Z"
        fill="white"
      />
      <path
        d="M1772.66 474.427L1774.15 463.128H1769.33L1767.84 474.427H1772.66ZM1778.85 499.288H1769.33L1771.44 483.467H1757.1L1761.03 454.088H1784.9L1778.85 499.288Z"
        fill="white"
      />
      <path
        d="M1815.37 474.427L1816.86 463.128H1812.04L1810.55 474.427H1815.37ZM1821.56 499.288H1812.04L1814.15 483.467H1799.81L1803.74 454.088H1827.6L1821.56 499.288Z"
        fill="white"
      />
      <path
        d="M1859.6 463.128H1854.78L1853.56 472.169H1858.38L1859.6 463.128ZM1851.15 490.247H1855.97L1857.16 481.21H1852.34L1851.15 490.247ZM1840.41 499.288L1842.82 481.21L1847.02 476.688L1844.04 472.169L1846.45 454.088H1870.32L1867.91 472.169L1863.74 476.688L1866.69 481.21L1864.27 499.288H1840.41Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.310547 0L1555.96 71.5993L126.01 50.5953L243.664 357.996L0.310547 0Z"
        fill="white"
      />
    </svg>
  );
}
